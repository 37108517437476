export default {
    data() {
        return {
            tarbar: [{
                    text: this.$t("menu.headerTitle"),
                    icon1: require('../../../assets/images/user_tab_icon_home_n.png'),
                    icon2: require('../../../assets/images/user_tab_icon_home_s.png'),
                    link: '/base'
                },
                {
                    text: this.$t("buyCar.headerTitle"),
                    icon1: require('../../../assets/images/user_tab_icon_shoppingcart_n.png'),
                    icon2: require('../../../assets/images/user_tab_icon_shoppingcart_s.png'),
                    link: '/buy-car'


                }, {
                    text: this.$t("user.headerTitle"),
                    icon1: require('../../../assets/images/user_tab_icon_me_n.png'),
                    icon2: require('../../../assets/images/user_tab_icon_me_s.png'),
                    link: '/user'
                }
            ],
            tarbarIndex: 0
        }
    },
    created() {
        // this.getuserinfo();
    },
    methods: {
        changeTarbar: function(index) {
            this.tarbarIndex = index;
            this.$router.push(this.tarbar[index].link)
        }
    }
}