import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import Vant from 'vant';
import 'vant/lib/index.css';
import './mixins/mixins'
import './net/index'
Vue.use(VueI18n);
Vue.config.productionTip = false;

const i18n = new VueI18n({
    // 双语
    locale: "en",
    messages: {
        zh: require("./components/lang/zh"),
        en: require("./components/lang/en"),
    },
});

new Vue({
    Vant,
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");