module.exports = {
    login: {
        title: "账号登录",
        account: '请输入账号',
        password: '请输入密码',
        forget: '忘记密码？',
        register: '新用户注册',
        btnText: '登录',
        hint: '登录代表你同意',
        agreement1: '《使用协议》',
        agreement2: '《隐私协议》',
    },
    register: {
        title: "快捷注册",
        phone: '请输入手机号',
        code: '请输入验证码',
        getCode: '获取验证码',
        getagain: '秒后重新获取',
        unit: '秒',
        password: '请输入密码',
        passwordAgain: '请确定密码',
        btnText: '确定',
        hint: '注册代表你同意',
        agreement1: '《使用协议》',
        agreement2: '隐私协议',
    },
    forget: {
        headerTitle: '忘记密码',
        phone: '请输入手机号',
        code: '请输入验证码',
        getCode: '获取验证码',
        password: '请输入密码',
        passwordAgain: '请确定密码',
        btnText: '确定',
    },
    agreement: {
        headerTitle: '使用协议',
        headerTitle1: '隐私协议',
    },
    base: {
        tarbarText1: '首页',
        tarbarText2: '购物车',
        tarbarText3: '我的',
    },
    menu: {
        headerTitle: '菜品列表',
        hintText: '购物车',
        btnText: "立即支付",
        modelHint: '已选规格',
        select: '选规格',
        pleaseSelect:'请先选择规格',
        joinText: '加入购物车',
        ide: '¥',
        quantity: '数量'
    },
    detail: {
        headerTitle: '菜品详情',
        daySale: '日销',
        daySurplus: '今日剩余',
        ide: '¥',
        hintText: '菜品详情',
        classifly: '分类',
        zhaopaie: '招牌菜品',
        describe: '菜品描述',
        joinText: '添加到购物车',
        yes: '是',
        no: '不是'
    },
    buyCar: {
        headerTitle: '购物车',
        edit: '编辑',
        cancel: '取消',
        add: '全选',
        sum: '合计',
        ide: '¥',
        result: '结算',
        delete: '删除'
    },
    order: {
        headerTitle: '我的订单',
        sum: '合计',
        ide: '¥',
        coupon: '优惠券',
        discount: '已优惠',
        subtotal: '小计',
        remarks: '备注',
        place: '请输入',
        tip: '小费',
        next: '下一步',
        selText1: '本人账单',
        selText2: '本桌账单',
        toast: '请选择菜品',
    },
    pay: {
        headerTitle: '我的订单',
        payHint: '支付金额',
        ide: '¥',
        selHint: '请选择付款账户',
        payText1: '余额支付',
        payText2: 'PayPal',
        payText3: '线下支付',
        btnText: '确定',
        submit: '提交订单'
    },
    user: {
        headerTitle: '我的',
        myOrder: '我的订单',
        vip: '会员卡',
        wallet: '钱包',
        coupon: '我的优惠券',
        extension: '推广',
        message: '会员留言'
    },
    userData: {
        headerTitle: '个人资料',
        avtar: '头像',
        nickName: '昵称',
    },
    myOrder: {
        headerTitle: '我的订单',
        clear: '清空',
        state1: '全部',
        state2: '待支付',
        state3: '已接单',
        state4: '已完成',
        orderNum: '订单号',
        sum: '共',
        unit: '件',
        btnCancel: '取消订单',
        btnDetail: '订单详情',
        btnPay: '立即支付',

    },
    orderDetail: {
        headerTitle: '订单详情',
        state1: '全部',
        state2: '待支付',
        state3: '已接单',
        state4: '已完成',
        activity: '满减活动',
        sum: '合计',
        ide: '¥',
        discount: '已优惠',
        infoHint: '订单信息',
        payText1: '余额支付',
        payText2: 'PayPal',
        payText3: '线下支付',
        orderNum: '订单号码',
        orderDate: '下单时间',
        payType: '支付方式',
        tableNum: '桌号',
        btnCancel: '取消订单',
        btnDetail: '订单详情',
        btnPay: '立即支付',
    },
    setting: {
        headerTitle: '设置',
        changePass: '修改密码',
        changPhone: '修改手机号',
        about: '关于我们',
        btnText: '退出登录',

    },
    changePass: {
        headerTitle: '修改密码',
        phone: '请输入手机号',
        code: '请输入验证码',
        getCode: '获取验证码',
        password: '请输入密码',
        passwordAgain: '请确定密码',
        btnText: '确认'
    },
    changePhone: {
        headerTitle: '修改手机号',
        phone: '请输入手机号',
        code: '请输入验证码',
        getCode: '获取验证码',
        btnText: '确认'
    },
    vip: {
        headerTitle: '会员卡',
        vipText: '会员卡',
        rank: '会员等级',
        payText: '充值',
        rank1: "金会员",
        rank2: "白金会员",
        rank3: "钻石会员",
        vipDate: '会员到期时间',
        resultText: '充值规则'
    },
    coupon: {
        headerTitle: '优惠券',
        use: '前可用',
        btnText: '立即使用',
        hisBtn: '历史',
        ide: '¥',
        meet:'满',
        minus:'减'
    },
    extension: {
        headerTitle: '推广中心',
        hint: '累计收益',
        detail: '收益明细',
        team: "我的团队(人)",
        teamDetail: '团队详情',
        extCode: '我的推广码',
        btnText: '立即邀请',
        cop: 'Copy',
        success: 'Copy Successfully'
    },
    wallet: {
        headerTitle: '钱包',
        bill: '账单',
        hint: '账户余额(元)',
        btnText: '充值'
    },
    recharge: {
        headerTitle: '充值',
        hint: '充值金额(元)',
        enterHint:'请输入充值金额',
        ide: '¥',
        payHint: '请选择付款账号',
        payText1: 'PayPal',
        payText2: '线下支付',
        btnText: '立即充值',
        rechargeSuccess:'充值成功'
    },
    bill: {
        headerTitle: '账单',
        billText1: '充值',
        billText2: '点餐',
        payText1: '余额支付',
        payText2: 'PayPal',
        payText3: '线下支付',
        ide: '¥',
    },
    message: {
        headerTitle: '会员留言',
        palceholder: '请输入您的留言',
        btnText: '提交'
    },
    history: {
        headerTitle: '历史优惠券',
        use: '前可用',
        btnText: '已使用',
        ide: '¥'
    },
    profit: {
        headerTitle: '收益明细',
        ide: '¥',
    },
    myTeam: {
        headerTitle: '我的队员',
        type1: '一级',
        type2: '二级',
        rank1: "金会员",
        rank2: "白金会员",
        rank3: "钻石会员",
    },
    noData: {
        text: '暂无数据'
    },
    info: {
        tip: '提示',
        del: '确认删除这些菜品?',
        cancelInfo: '确定取消订单?',
        emptyInfo: '确定清空订单?',
        noOrders: '暂无订单',
        btnCancel: '取消',
        btnConfirm: '确定'
    }
};