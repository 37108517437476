module.exports = {
    login: {
        title: "account login",
        account: 'Enter account',
        password: 'Enter Password',
        forget: 'Forget Password',
        register: 'Create New Account',
        btnText: 'Login',
        hint: 'By signing in and click the box you agree to the agreements',
        agreement1: "User's Agreement ",
        agreement2: "User's Agreement",
    },
    register: {
        title: "Fast Registeration",
        phone: 'Phone number',
        code: 'Verification Code',
        getCode: 'Send Verification Code',
        getagain: 'Retrieve after',
        unit: 'seconds',
        password: 'Password',
        passwordAgain: 'Password Confirmation',
        btnText: 'Confirm',
        hint: 'By signing in and click the box you agree to the agreements',
        agreement1: "User's Agreement",
        agreement2: 'Privacy Agreement',
    },
    forget: {
        headerTitle: ' Forget Password',
        phone: 'Phone number',
        code: 'Verification Code',
        getCode: 'Send Verification Code',
        password: 'Password',
        passwordAgain: 'Password Confirmation',
        btnText: 'Confirm',
    },
    agreement: {
        headerTitle: "User's Agreement",
        headerTitle1: 'Privacy Agreement',
    },
    about: {
        headerTitle: "About us",
    },
    base: {
        tarbarText1: 'Home',
        tarbarText2: 'Shopping Cart',
        tarbarText3: 'My Order',
    },
    menu: {
        headerTitle: 'Menu',
        hintText: 'Shopping Cart',
        btnText: " Pay Now",
        modelHint: ' Selected  Specifics',
        select: 'Selected',
        pleaseSelect: 'Please select specifications first',
        joinText: 'Add to Cart',
        ide: '$',
        quantity: 'quantity'
    },
    detail: {
        headerTitle: 'Item Details',
        daySale: "Today's Sales ",
        daySurplus: 'Remaining Today',
        hintText: 'Item Details',
        classifly: 'Category ',
        ide: '$',
        zhaopaie: 'Featured Food',
        describe: 'Description',
        joinText: 'Add to the Cart',
        yes: 'Yes',
        no: 'No'
    },
    buyCar: {
        headerTitle: 'Shopping Cart',
        edit: 'Edit',
        cancel: 'Cancel',
        add: 'Select All',
        sum: 'Sum',
        ide: '$',
        result: 'Pay',
        delete: 'Delete'
    },
    order: {
        headerTitle: 'My Order',
        sum: 'Sum',
        ide: '$',
        coupon: 'Coupons',
        discount: ' discounted Amount',
        subtotal: 'Subtotal',
        remarks: 'Remarks',
        place: 'place',
        next: 'Next',
        tip: 'Tip',
        selText1: 'Personal bills',
        selText2: 'Table bills',
        toast: 'Please select dishes',
    },
    pay: {
        headerTitle: 'My Order',
        payHint: 'Amount',
        ide: '$',
        selHint: 'Select Payment Account',
        payText1: 'Pay With Balance',
        payText2: 'PayPal',
        payText3: 'Pay Inperson',
        btnText: 'Confrim',
        submit: 'Submit Order'
    },
    user: {
        headerTitle: 'My',
        myOrder: 'My order',
        vip: 'Member Card',
        wallet: 'Wallet',
        coupon: 'My Coupon',
        extension: 'Promotions',
        message: "Member's Messages ",
    },
    userData: {
        headerTitle: 'Personal Information',
        avtar: 'Profile Picture',
        nickName: 'Nick Name',
    },
    myOrder: {
        headerTitle: 'My order',
        clear: 'Clear',
        state1: 'All',
        state2: 'Unpaid',
        state3: 'Orders Taken',
        state4: 'Finished Orders',
        orderNum: 'Order Number',
        sum: 'Sum',
        unit: 'Unit',
        btnCancel: 'Cancel Order',
        btnDetail: 'Order Details',
        btnPay: 'Pay Now',

    },
    orderDetail: {
        headerTitle: 'Order Details',
        state1: 'Sum',
        state2: 'Unpaid',
        state3: 'Orders Taken',
        state4: 'Finished Orders',
        activity: 'Promotion Activities',
        sum: 'Sum',
        ide: '$',
        discount: 'Discounted Amount',
        infoHint: 'Order Information',
        payText1: 'Pay with Balance',
        payText2: 'PayPal',
        payText3: 'Pay Inperson',
        orderNum: 'Order Number',
        orderDate: 'Order Time',
        payType: 'Payment Method',
        tableNum: 'Table Number',
        btnCancel: 'Cancel Order',
        btnDetail: 'Order Details',
        btnPay: 'Pay Now',
    },
    setting: {
        headerTitle: 'Settings',
        changePass: 'Change Password',
        changPhone: 'Change Phone number',
        about: 'About Us',
        btnText: 'Exit',

    },
    changePass: {
        headerTitle: 'Change Password',
        phone: 'Input Phone Number',
        code: 'Input Verification Code',
        getCode: 'Sent Verification Code',
        password: 'Input Password',
        passwordAgain: 'Confirm Password',
        btnText: 'Confirm'
    },
    changePhone: {
        headerTitle: 'Change Phone Number',
        phone: 'Input New phone number',
        code: 'Input Verification Code',
        getCode: 'Sent Verification Code',
        btnText: 'Confirm'
    },
    vip: {
        headerTitle: 'Membership Card Number',
        vipText: 'Membership Card',
        rank: 'Membership Level',
        payText: 'Recharge',
        rank1: "Silver",
        rank2: "Gold",
        rank3: "Platinum",
        vipDate: 'Membership Expiration Date',
        resultText: 'Recharge Explanation'
    },
    coupon: {
        headerTitle: 'Coupons',
        use: 'Valid Before',
        btnText: 'Use Now',
        hisBtn: 'History',
        ide: '$',
        meet:'Meet',
        minus:'Minus'
    },
    extension: {
        headerTitle: 'Promotion Center',
        hint: 'Accumulated Income',
        detail: 'Income Details',
        team: 'My Team',
        teamDetail: 'Team Details',
        extCode: 'My Invite Code',
        btnText: 'Invite Now'
    },
    wallet: {
        headerTitle: 'Wallet',
        bill: 'Bill',
        hint: 'Balance',
        btnText: 'Recharge'
    },
    recharge: {
        headerTitle: 'Recharge',
        hint: 'Recharge Amount',
        enterint:'Please enter the recharge amount',
        ide: '$',
        payHint: 'Select Payment Account',
        payText1: 'PayPal',
        payText2: 'Pay Inperson',
        btnText: 'Recharge Now',
        rechargeSuccess:'be recharged successfully'
    },
    bill: {
        headerTitle: 'Bill',
        billText1: 'Recharge',
        billText2: 'Order',
        payText1: 'Pay with Balance',
        payText2: 'PayPal',
        ide: '$',
        payText3: 'Pay Inperson',
    },
    message: {
        headerTitle: "Member's Messages ",
        palceholder: 'Please leave your Messages',
        btnText: 'Submit'
    },
    history: {
        headerTitle: 'Historic Coupon',
        use: 'Use Before',
        btnText: 'Used ',
        ide: '$'
    },
    profit: {
        headerTitle: 'Income Details',
        ide: '$',
    },
    myTeam: {
        headerTitle: "My'Team ",
        type1: 'Tier 1',
        type2: 'Tier 2',
        rank1: "Silver",
        rank2: "Gold",
        rank3: "Platinum"
    },
    noData: {
        text: 'No data'
    },
    info: {
        tip: 'Tips',
        del: 'Are you sure to delete these dishes?',
        cancelInfo: 'Are you sure to cancel the order?',
        emptyInfo: 'Are you sure to clear all orders',
        noOrders: 'No orders',
        btnCancel: 'Cancel',
        btnConfirm: 'Confirm'
    }
};