import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userInfo: localStorage["user"] ?
            JSON.parse(localStorage["user"]) : {} || {},
    },
    getters: {},
    mutations: {
        setUserInfo: function(state, payload) {
            (state.userInfo = payload), (state.userType = payload.type);
        },
    },
    actions: {},
    modules: {},
});