<template>
    <div class="base-p vh flex">
       <div class="rv-box">
            <router-view />
       </div>
       <div class="flex-row tarbar">
           <div v-for="(item,index) in  tarbar" :key="index"  class="tar-item flex" @click="changeTarbar(index)">
               <img class="tar-icon" :src="tarbarIndex == index ? item.icon2:item.icon1" alt="">
               <p :class="['c333', 'f22']">{{item.text}}</p>
           </div>
       </div>
    </div>
</template>

<script>
import mixins from './mixins/index';
export default{
    mixins,
    components: {
    },
}
</script>

<style scoped>
@import './src/style.css';
</style>