import Vue from 'vue'
import VueRouter from 'vue-router'
import Base from '../views/base/index.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/base',
    name: 'base',
    component: Base,
    children: [{
        path: '/',
        name: 'menu',
        component: () =>
            import ("../views/base/child/menu/index.vue"),
    }, {
        path: '/buy-car',
        name: 'BuyCar',
        component: () =>
            import ("../views/base/child/buy-car/index.vue"),
    }, {
        path: '/user',
        name: 'user',
        component: () =>
            import ("../views/base/child/user/index.vue"),
    }, ]
}, {
    path: '/',
    name: 'login',
    component: () =>
        import ("../views/login/index.vue"),
}, {
    path: '/register',
    name: 'register',
    component: () =>
        import ("../views/register/index.vue"),
}, {
    path: '/forget',
    name: 'forget',
    component: () =>
        import ("../views/forget/index.vue"),
}, {
    path: '/agreement',
    name: 'agreement',
    component: () =>
        import ("../views/agreement/index.vue"),
}, {
    path: '/detail',
    name: 'detail',
    component: () =>
        import ("../views/com-detail/index.vue"),
}, {
    path: '/order',
    name: 'order',
    component: () =>
        import ("../views/order/index.vue"),
}, {
    path: '/pay',
    name: 'pay',
    component: () =>
        import ("../views/pay/index.vue"),
}, {
    path: '/user-data',
    name: 'useradata',
    component: () =>
        import ("../views/user-data/index.vue"),
}, {
    path: '/setting',
    name: 'setting',
    component: () =>
        import ("../views/setting/index.vue"),
}, {
    path: '/changepass',
    name: 'changepass',
    component: () =>
        import ("../views/change-pass/index.vue"),
}, {
    path: '/changephone',
    name: 'changephone',
    component: () =>
        import ("../views/change-phone/index.vue"),
}, {
    path: '/about',
    name: 'about',
    component: () =>
        import ("../views/about/index.vue"),
}, {
    path: '/myorder',
    name: 'myorder',
    component: () =>
        import ("../views/my-order/index.vue"),
}, {
    path: '/order-detail',
    name: 'orderDetail',
    component: () =>
        import ("../views/order-detail/index.vue"),
}, {
    path: '/vip',
    name: 'vip',
    component: () =>
        import ("../views/vip/index.vue"),
}, {
    path: '/wallet',
    name: 'wallet',
    component: () =>
        import ("../views/wallet/index.vue"),
}, {
    path: '/bill',
    name: 'bill',
    component: () =>
        import ("../views/bill/index.vue"),
}, {
    path: '/coupon',
    name: 'coupon',
    component: () =>
        import ("../views/coupon/index.vue"),
}, {
    path: '/recharge',
    name: 'recharge',
    component: () =>
        import ("../views/recharge/index.vue"),
}, {
    path: '/extension',
    name: 'extension',
    component: () =>
        import ("../views/extension/index.vue"),
}, {
    path: '/couponhistory',
    name: 'couponhostory',
    component: () =>
        import ("../views/coupon-history/index.vue"),
}, {
    path: '/profit',
    name: 'profit',
    component: () =>
        import ("../views/profit/index.vue"),
}, {
    path: '/myteam',
    name: 'myteam',
    component: () =>
        import ("../views/my-team/index.vue"),
}, {
    path: '/message',
    name: 'message',
    component: () =>
        import ("../views/message/index.vue"),
}]

const router = new VueRouter({
    routes
})

export default router