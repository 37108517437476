import { Toast } from "vant";
import Vue from "vue";
import { mapState } from "vuex";

Vue.mixin({

    data() {
        return {
            userInfo: {
                userCard: {}
            },
            xyInfo: {
                czgz: "",
                gywm: "",
                syxy: "",
                yszc: ""
            }
        }
    },
    created() {

    },
    methods: {
        toLink: function(link, query) {
            this.$router.push({
                path: link,
                query: query
            })
        },
        getuserinfo: function() {
            this.$post('User/getuserinfo', {
                token: true
            }).then(res => {
                if (res.code == 1) {
                    this.userInfo = res.data.userinfo
                    this.setStorage('token', res.data.userinfo.token)
                    this.setStorage('user', JSON.stringify(res.data.userinfo))

                } else {
                    return Toast(res.msg)
                }
            }).finally(() => {})
        },
        setStorage: function(text, info) {
            localStorage.setItem(text, info);
        },
        getConfig: function() {
            this.$post('Index/config', {}).then(res => {
                if (res.code == 1) {
                    this.xyInfo = res.data
                }
            }).finally(() => {})
        },
        backPage() {
            this.$roouter.go(-1)
        }
    }
})